<template>
  <div id="project-afkir-dini-add" data-app>
    
    <ValidationObserver ref="formAdd">
      <b-form @submit="onSubmit" @reset="onReset" v-if="show">
      <!--begin::Form-->
        <div class="card card-custom gutter-b">
          <!--begin::Header-->
          <div class="card-header py-3">
              <div class="card-title align-items-start flex-column">
				          <h3 class="card-label font-weight-bolder text-dark">Tambah Afkir Dini</h3>
                  <span class="text-muted font-weight-bold font-size-sm mt-1">Data afkir dini diisi sesuai yang ada di berita acara</span>
              </div>
          </div>
          <!--end::Header-->

          <div class="card-body">
              <!--begin::Form Group-->
              <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">No. SE</label>
                  <div class="col-lg-9 col-xl-7">
                      <validationProvider name="Nomor Surat Edaran" rules="required" v-slot="{ valid, errors }">
                          <b-form-group
                              id="input-group-se-1" 
                              label-for="input-afkirdini-1"
                              :invalid-feedback="errors[0]"
                              class="mb-0"
                              :state="valid">
                              <b-form-select2
                              id="input-afkirdini-1"
                              variant="solid"
                              size="lg"
                              :options="opsSE"
                              v-model="form.no_se"
                              required
                              :state="valid"
                              >
                              </b-form-select2>
                          </b-form-group>
                      </validationProvider>
                  </div>
              </div>
              <!--begin::Form Group-->
              <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">Farm</label>
                  <div class="col-lg-9 col-xl-7">
                      <validationProvider name="Farm" rules="required" v-slot="{ valid, errors }">
                          <b-form-group
                              id="input-group-se-2" 
                              label-for="input-afkirdini-2"
                              :invalid-feedback="errors[0]"
                              class="mb-0"
                              :state="valid">
                              <b-form-select2
                              id="input-afkirdini-2"
                              variant="solid"
                              size="lg"
                              :options="opsFarm"
                              v-model="form.farm"
                              required
                              :state="valid"
                              >
                              </b-form-select2>
                          </b-form-group>
                      </validationProvider>
                  </div>
              </div>
              <!--begin::Form Group-->
              <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">No.BA</label>
                  <div class="col-lg-9 col-xl-7">
                      <validationProvider name="Nomor Berita Acara" rules="required|max:200" v-slot="{ valid, errors }">
                          <b-form-group
                              id="input-group-se-3" 
                              label-for="input-afkirdini-3"
                              :invalid-feedback="errors[0]"
                              class="mb-0"
                              :state="valid">
                              <b-form-input
                                id="input-afkirdini-3"
                                v-model="form.no_ba"
                                class="form-control form-control-solid"
                                required
                                size="lg"
                                placeholder="Nomor Berita Acara"
                                :invalid-feedback="errors[0]"
                                :state="valid"
                              ></b-form-input>
                          </b-form-group>
                      </validationProvider>
                  </div>
              </div>
              <!--begin::Form Group-->
              <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">Tgl. Afkir</label>
                  <div class="col-lg-9 col-xl-7">
                      <validationProvider name="Tanggal Afkir" rules="required" v-slot="{ valid, errors }">
                          <b-form-group
                              id="input-group-se-4" 
                              label-for="input-afkirdini-4"
                              :invalid-feedback="errors[0]"
                              description="pilih tanggal sesuai yang ada di berita acara."
                              class="mb-0"
                              :state="valid">
                              <b-form-datepicker
                                id="input-afkirdini-4"
                                class="form-control form-control-solid pr-0"
                                v-model="form.tgl_afkir"
                                size="lg"
                                required
                                placeholder="Tanggal Afkir"
                                :state="valid"
                              ></b-form-datepicker>
                          </b-form-group>
                      </validationProvider>
                  </div>
              </div>
              <b-row v-for="(rinc, index) in form.rincian" :key="index">
                <b-col cols="10">
                  <h5 class="mt-5 text-muted">Rincian {{index + 1}}</h5>
                </b-col>
                <b-col cols="2" class="text-right">
                  <b-button v-show="(form.rincian.length > 1) ? true : false" @click="deleteRincian(index)" size="sm" variant="link" class="mt-5 text-hover-primary">
                    <i class="flaticon2-rubbish-bin-delete-button p-0"></i>
                  </b-button>
                </b-col>
                <b-col cols="12" md="4" sm="12">
                  <validationProvider :name="`Rin ${index+1} Sex`" rules="required" v-slot="{ errors, valid }">
                    <b-form-group 
                    :id="`input-group-${index}-3`" 
                    label="Sex:" 
                    :label-for="`input-${index}-3`"
                    :invalid-feedback="errors[0]"
                    :state="valid">
                      <b-form-select2
                      :id="`input-${index}-3`"
                      variant="solid"
                      size="lg"
                      :options="opsSex"
                      v-model="rinc.sex"
                      required
                      :state="valid">
                      </b-form-select2>
                    </b-form-group>
                  </validationProvider>
                </b-col>
                <b-col cols="12" md="4" sm="12">
                  <validationProvider :name="`Rin ${index+1} Umur`" rules="required|min_value:1|max_value:150" v-slot="{ errors, valid }">
                    <b-form-group 
                    :id="`input-group-${index}-1`" 
                    label="Umur:" 
                    :label-for="`input-${index}-1`"
                    :invalid-feedback="errors[0]"
                    :state="valid">
                      <vue-number-input 
                        :attrs="{ id: `input-${index}-1`, class: `b-form-spinbutton form-control form-control-solid d-flex align-items-stretch` }"
                        v-model="rinc.umur"
                        :min="0"
                        :max="150"
                        size="large"
                        required
                        center
                        controls
                      ></vue-number-input>
                    </b-form-group>
                  </validationProvider>
                </b-col>
                <b-col cols="12" md="4" sm="12">
                  <validationProvider :name="`Rin ${index+1} Jumlah`" rules="required|min_value:1|max_value:9999999999" v-slot="{ errors, valid }">
                    <b-form-group 
                    :id="`input-group-${index}-2`" 
                    label="Jumlah:" 
                    :label-for="`input-${index}-2`"
                    :invalid-feedback="errors[0]"
                    :state="valid">
                      <vue-number-input 
                        :attrs="{ id: `input-${index}-2`, class: `b-form-spinbutton form-control form-control-solid d-flex align-items-stretch` }"
                        v-model="rinc.jumlah"
                        :min="0"
                        :max="9999999999"
                        size="large"
                        required
                        center
                        controls
                      ></vue-number-input>
                    </b-form-group>
                  </validationProvider>
                </b-col>
              </b-row>

              <b-row class="mb-5">
                <b-col cols="12" class="text-right">
                  <b-button @click="addRincian" variant="link" class="mt-5 text-hover-primary">
                    <i class="flaticon2-plus-1 p-0"></i>
                    Add Rincian
                  </b-button>
                </b-col>
              </b-row>

              <!--begin::Form Group-->
              <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">File Berita Acara</label>
                  <div class="col-lg-9 col-xl-7">
                    <a v-if="tempAttachments.length > 0" @click="removefiles" class="btn btn-light-primary font-weight-bold">Remove files</a>
                    <vue-dropzone
                      ref="myVueDropzone"
                      :include-styling="false"
                      :useCustomSlot="true"
                      id="dropzone"
                      @vdropzone-file-added="fileAdded"
                      @vdropzone-upload-progress="uploadProgress"
                      @vdropzone-success="uploadSuccess"
                      @vdropzone-error="uploadError"
                      :options="dropzoneOptions"
                    >
                      <a v-if="tempAttachments.length == 0" class="btn btn-light-primary font-weight-bold">Attach files</a>
                      <span v-else></span>
                    </vue-dropzone>
                    <span class="form-text text-muted">Ukuran file Maksimal 1MB (MegaByte) </span>

                    <div v-if="tempAttachments.length > 0" class="mt-3">  
                      <div v-for="(item, key) in tempAttachments" :key="key" class="alert alert-light" :class="{'cursor-pointer': !item.message}" @click="downloadPdf(item)">
                        <p> {{ item.title }} ({{ (item.size/1000000).toFixed(2) }} MB) </p>
                        <b-progress height="7px" :value="item.progress" :max="100" show-progress animated></b-progress>
                        <span v-if="item.message" class="form-text text-danger">{{ item.message }}</span>
                      </div>
                    </div>
                  </div>
              </div>
        
          </div>
      

          <div class="card-footer">
            <div class="row">
              <div class="col-lg-3"></div>
              <div class="col-lg-9">
                <button  
                  @click="onSubmit" 
                  ref="kt_submit"
                  class="btn btn-primary font-weight-bold"
                >
                  <i class="la la-send"></i>
                  Selanjutnya
                </button>
                &nbsp;
                <button
                  @click="onReset"
                  class="btn btn-clean font-weight-bold"
                >
                  <i class="la la-recycle"></i>
                  Reset
                </button>
              </div>
            </div>
          </div>
        </div>
    
      <!--end::Form-->
      </b-form>
    </ValidationObserver>

  <v-dialog
    v-model="dialogEnd"
    max-width="300">
      <v-card>
        <v-card-title class="headline">Info</v-card-title>

        <v-card-text>
          Ingin tambah data lagi?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <b-button
          variant="link-success"
          class="m-3"
          @click="$router.push({ name: 'project-afkir-dini-histori' })">
            TIDAK
          </b-button>

          <b-button
          variant="link-danger"
          class="m-3"
          @click="onStayPage">
            YA
          </b-button>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import PerusahaanService from "@/core/services/api/master/perusahaan.service";
import MasterService from "@/core/services/api/master/master.service";
import AfkirDiniService from "@/core/services/api/data/afkirdini.service";
import ErrorService from "@/core/services/error.service";
import JwtService from "@/core/services/jwt.service";

import vue2Dropzone from 'vue2-dropzone'

export default {
  name: "project-afkir-dini-add",
  components: {
    vueDropzone: vue2Dropzone
  },
  data() {
    return {
      form: {
        no_se: null,
        farm: null,
        no_ba: null,
        file: null,
        tgl_afkir: new Date().toISOString().slice(0,10),
        rincian: [{
          umur: 0,
          jumlah: 0,
          sex: null
        }]
      },
      tempAttachments: [],
      dropzoneOptions: {
        url: `${process.env.VUE_APP_API_URL }/v2/data/afkir-dini/file`,
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${JwtService.getToken()}`
        },
        maxFilesize: 1,
        maxFiles: 1,
        acceptedFiles: 'application/pdf',
        includeStyling: false,
        previewsContainer: false,
        parallelUploads: 20
      },
      opsSE: [
        { text: 'Silakan pilih Surat Edaran', value: null },
      ],
      opsFarm: [
        { text: 'Silakan pilih Farm', value: null },
      ],
      opsSex: [
        { text: 'Silakan pilih Sex', value: null }, 
      ],
      show: true,
      dialogEnd: false
    }
  },
  computed: {
    ...mapGetters(["currentUser"]),
    cekRincian () {
      const lookup = this.form.rincian.reduce((a, e) => {
        a[e.sex] = ++a[e.sex] || 0;
        return a;
      }, {});

      return this.opsSex.filter(e => lookup[e.value] && e.value !== null)
    },
    listRincian () {
        if (this.cekRincian.length > 0) {
            return this.cekRincian.map(function(item) {return ` ${item['text']}`})
        }
        return ''
    }
  },
  methods: {
    addRincian(){
      this.form.rincian.push({
        umur: 0,
        jumlah: 0,
        sex: null
      })
    },
    deleteRincian(index){
      if (this.form.rincian.length > 1) {
        this.form.rincian.splice(index,1);
      }
      else {
        this.form.rincian = [
          {
            umur: 0,
            jumlah: 0,
            sex: null
          }
        ]
      }
    },    
    onStayPage() {
      this.dialogEnd = false
      this.onReset()
    },    
    getSE() {
      AfkirDiniService.getAfkirDiniSE()
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsSE = [{
                  value: null,
                  text: "Silakan pilih Surat Edaran"
                }]
                return;
            }

            this.opsSE = res.data.data
            this.opsSE.push({
              value: null,
              text: "Silakan pilih Surat Edaran"
            })
        })
        .catch((err) => ErrorService.status(err))
    },
    getFarm() {
      PerusahaanService.getPerusahaanFarm()
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsFarm = [{
                  value: null,
                  text: "Silakan pilih Farm"
                }]
                return;
            }

            this.opsFarm = res.data.data
            this.opsFarm.push({
              value: null,
              text: "Silakan pilih Farm"
            })
        })
        .catch((err) => ErrorService.status(err))
    },
    getSex() {
      MasterService.getTernakSex()
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsSex = [{
                  value: null,
                  text: "Silakan pilih Sex"
                }]
                return;
            }

            this.opsSex = res.data.data
            this.opsSex.push({
              value: null,
              text: "Silakan pilih Sex"
            })
        })
        .catch((err) => ErrorService.status(err))
    },
    
    removefiles () {
        this.$refs.myVueDropzone.removeAllFiles(true);
        this.form.file = null
        this.tempAttachments= [];
    },
    fileAdded(file) {      
      let attachment = {};
      attachment._id = file.upload.uuid;
      attachment.title = file.name;
      attachment.type = "file";
      attachment.extension = "." + file.type.split("/")[1];
      attachment.isLoading = true;
      attachment.progress = null;
      attachment.size = file.size;
      attachment.message = null;
      this.tempAttachments = [...this.tempAttachments, attachment];

    },
    uploadProgress(file, progress, bytesSent) {
      this.tempAttachments.map(attachment => {
        if (attachment._id === file.upload.uuid) {
          attachment.progress = `${Math.floor(progress)}`;
        }
      });
    },
    uploadSuccess(file, response) {
      this.form.file = response.data

      ErrorService.message(response)
    },
    uploadError(file, message, xhr) {
      this.tempAttachments.map(attachment => {
        if (attachment._id === file.upload.uuid) {
          attachment.message = message;
        }
      });
    },
    downloadPdf(file) {
      if (file.filename && file.progress == 100 && !file.message) {
        window.open(`${process.env.VUE_APP_API_URL}/dokumen/afkir-dini/thum/${file.filename}`, "_blank"); 
      }
    },
    removeSpinner(button) { 
      button.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    },
    onSubmit(evt) {
      evt.preventDefault()
      this.$refs.formAdd.validate().then(success => {
        if (!success) {
            ErrorService.message({
              action: 'Peringatan',
              message: 'Silahkan lengkapi form data terlebih dahulu',
            })
            return;
        }

        if (!this.form.file) {
          ErrorService.message({
              action: 'Peringatan',
              message: 'Upload file berita acara untuk menyimpan data',
            })
          return;
        }

        if (this.form.rincian.length == 0) {
            ErrorService.message({
              action: 'Peringatan',
              message: 'Rincian data harus diisi',
            })
            return;
        }

        if (this.cekRincian.length > 0) {
            ErrorService.message({
              action: 'Peringatan',
              message: `Double input Rincian: ${this.listRincian}`,
            })
            return;
        }

        // set spinner to submit button
        const submitButton = this.$refs["kt_submit"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        // dummy delay
        // setTimeout(() => {
            AfkirDiniService.addAfkirDini(this.form)
                .then((res) => {
                    this.removeSpinner(submitButton)
                    ErrorService.message(res.data)
                    
                    if (res.data.status) {
                      this.dialogEnd = true
                    }
                    return;
                })
                .catch((err) => {
                  this.removeSpinner(submitButton)
                  ErrorService.status(err)
                })
        // }, 1000);
      })
    },
    onReset() {
      // Reset our form values
      this.form.no_se = null
      this.form.farm = null
      this.form.no_ba = null
      this.form.tgl_afkir = null
      this.form.file = null
      this.form.rincian = [
          {
            umur: 0,
            jumlah: 0,
            sex: null
          }
      ]
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Afkir Dini" }]);
    if (!this.currentUser.menu.afkir_dini) {
      return this.$router.push({ name: 'dashboard'}).catch(()=>{})
    }
    this.getSE()
    this.getFarm()
    this.getSex()
  },
};
</script>